const AppName = process.env.VUE_APP_NAME;
const AppUrl = process.env.VUE_APP_CORE;

const COREAPI = process.env.VUE_APP_CORE_API;
const CORESCOPE = process.env.VUE_APP_CORE_API_SCOPE;

const AppClientID = process.env.VUE_APP_CLIENT_ID;
const AppLoginAuthority = process.env.VUE_APP_AUTHORITY;
const AppLoginFlow = process.env.VUE_APP_LOGIN_FLOW;
const AppSignUpFlow = process.env.VUE_APP_SIGNUP_FLOW;
const AppScope = ["openid", "profile", `${AppClientID}`]

export const BaseConfig = {
  AppName,
  AppUrl,
  AppScope,
  AppClientID,
  AppLoginAuthority,
  AppLoginFlow,
  AppSignUpFlow
};

export const CoreAPIConfig = {
  COREAPI,
  CORESCOPE,
  AppName
};