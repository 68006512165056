import { axiosInstance } from '../../axios'
import { parseError, parseList } from '../../utils/validationmessage'

const GETSearchProcedure = 'api/PWAExternal/SearchProcedure'
const GETMapByLocation = 'api/PWAExternal/GetMapByLocation'
const GETSearchCategory = 'api/PWAExternal/SearchCategory'
const GETLocation = 'api/PWAExternal/GetLocation'
const GETSearchByCode = 'api/PWAExternal/SearchByCode'
const POSTCreateTenant = 'api/PWAExternal/RegisterTenant'

const createTenant = async function (data) {
  try {
    const response = await axiosInstance.publicAPIInstance.post(
      POSTCreateTenant,
      data
    )
    return response.data
  } catch (error) {
    return null
  }
}

const searchCategory = async function (locationId) {
  try {
    const response = await axiosInstance.publicAPIInstance.get(
      `${GETSearchCategory}/${locationId}`
    )
    return parseList(response)
  } catch (error) {
    return null
  }
}

const searchLocation = async function (locationId) {
  try {
    const response = await axiosInstance.publicAPIInstance.get(
      `${GETLocation}/${locationId}`
    )
    return parseList(response)
  } catch (error) {
    return null
  }
}

const searchLocationByCode = async function (payload) {
  try {
    const response = await axiosInstance.publicAPIInstance.post(
      GETSearchByCode,
      payload
    )
    return parseList(response)
  } catch (error) {
    return null
  }
}

const searchProcedureWithDefault = async function (locationId) {
  try {
    const response = await axiosInstance.publicAPIInstance.get(
      `${GETSearchProcedure}/${locationId}`
    )
    return response.data
  } catch (error) {
    return null
  }
}

const searchProcedure = async function (locationId, designationId, categoryId) {
  try {
    const response = await axiosInstance.publicAPIInstance.get(
      `${GETSearchProcedure}/${locationId}/designation/${designationId}/category/${categoryId}`
    )
    return response.data
  } catch (error) {
    return null
  }
}

const searchMapByLocation = async function (locationId) {
  try {
    const response = await axiosInstance.publicAPIInstance.get(
      `${GETMapByLocation}/${locationId}`
    )
    return response.data
  } catch (error) {
    return null
  }
}

export const externalData = {
  searchMapByLocation,
  searchProcedure,
  searchProcedureWithDefault,
  searchCategory,
  createTenant,
  searchLocation,
  searchLocationByCode,
}
